import request from '@/utils/request'

/**
 * 店铺相关接口
 * */

// 获取所有店铺信息
export function getShopList(params) {
  return request({
    url: '/cs/admin/shop/get',
    method: 'get',
    params,
  })
}

// 新增店铺
export function addShop(data) {
  return request({
    url: '/cs/admin/shop/add',
    method: 'post',
    data,
  })
}

// 编辑店铺
export function editShop(data) {
  return request({
    url: '/cs/admin/shop/update',
    method: 'PUT',
    data,
  })
}

// 删除店铺

export function deleteShop(shopId) {
  return request({
    url: `/cs/admin/shop/del/${shopId}`,
    method: 'delete',
  })
}

// 修改店铺状态
export function statusShop(shopId, status) {
  return request({
    url: `/cs/admin/shop/update/${status}/${shopId}`,
    method: 'PUT',
  })
}

/**
 * 省市县三级信息接口
 * */

// 获取省级信息
export function getProvinceInfo(params) {
  return request({
    url: '/cs/dict/getProvince',
    method: 'get',
    params,
  })
}

// 获取市级信息
export function getCityInfo(params) {
  return request({
    url: '/cs/dict/getCity',
    method: 'get',
    params,
  })
}

// 获取县级信息
export function getAreaInfo(params) {
  return request({
    url: '/cs/dict/getArea',
    method: 'get',
    params,
  })
}

// 店铺管理员绑定接口
export function linkByBindPhone(shopId) {
  return request({
    url: `/cs/admin/shopAccount/generate/bindUrl/${shopId}`,
    method: 'get',
  })
}

// 店铺管理员绑定列表
export function linkByBindPhoneList(shopId) {
  return request({
    url: `/cs/admin/shopAccount/get/${shopId}`,
    method: 'get',
  })
}

// 删除店铺管理员

export function deletelink(id) {
  return request({
    url: `/cs/admin/shopAccount/del/${id}`,
    method: 'DELETE',
  })
}

// 获取核销记录
export function getCheckList(params) {
  return request({
    url: `/cs/admin/shopAccount/write/off/info`,
    method: 'get',
    params,
  })
}
