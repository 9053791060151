<template>
  <div class="user-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item>
            <el-input v-model.trim="queryForm.usernameLike" clearable placeholder="请输入用户名" />
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">
      <el-table-column align="center" fixed label="用户名" min-width="120" prop="username" show-overflow-tooltip />
      <el-table-column align="center" label="手机号" min-width="120" prop="phone" show-overflow-tooltip />
      <el-table-column align="center" label="真实姓名" prop="realName" show-overflow-tooltip />
      <el-table-column align="center" label="用户昵称" min-width="120px" prop="nickName" show-overflow-tooltip />
      <el-table-column align="center" label="角色" min-width="120" prop="roleNames" show-overflow-tooltip />
      <el-table-column align="center" label="创建者" min-width="120" prop="createBy" show-overflow-tooltip />
      <el-table-column align="center" label="状态" prop="status" show-overflow-tooltip width="58">
        <template #default="scope">
          <span v-if="scope.row.status === 0">
            <el-tag size="mini" type="success">启用</el-tag>
          </span>
          <span v-if="scope.row.status === 1">
            <el-tag size="mini" type="danger">停用</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间" min-width="165" prop="createTime" show-overflow-tooltip />
      <el-table-column align="center" label="操作" show-overflow-tooltip width="195">
        <template #default="{ row }">
          <el-button @click="handleEdit(row)" type="text">编辑</el-button>

          <el-button type="text" @click="handleDelete(row)" v-if="!row.isAdmin">删除</el-button>

          <el-button v-if="!row.isAdmin" type="text" @click="handleShop(row)">
            分配店铺
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.page" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <edit ref="editRef" @fetch-data="fetchData" />

    <el-dialog v-model="showShop" title="分配店铺">
      <vab-query-form>
        <vab-query-form-left-panel :span="12">
          <el-form :inline="true" :model="queryFormShop" @submit.prevent>
            <el-form-item label="店铺名称">
              <el-input v-model.trim="queryFormShop.shopName" clearable placeholder="请输入名称" />
            </el-form-item>
            <el-form-item>
              <el-button :icon="Search" type="primary" @click="queryShopData">
                查询
              </el-button>
            </el-form-item>
          </el-form>
        </vab-query-form-left-panel>
      </vab-query-form>

      <el-table v-loading="listLoading" border :data="shopList" highlight-current-row style="width: 100%" @current-change="handleCurrentChangeCheck">
        <el-table-column label="店铺名称" prop="shopName" show-overflow-tooltip />
        <el-table-column label="联系电话1" prop="shopContactNumber" show-overflow-tooltip />
        <el-table-column label="联系地址" prop="shopContactNumber" show-overflow-tooltip>
          <template #default="{ row }">
            {{ row.shopProvince }} - {{ row.shopCity }} - {{ row.shopArea }} -
            {{ row.shopAddress }}
          </template>
        </el-table-column>
        <el-table-column label="营业时间" prop="shopBusinessTime" show-overflow-tooltip />

        <el-table-column label="状态" prop="status" show-overflow-tooltip>
          <template #default="{ row }">
            <el-switch v-model="row.status" size="large" active-text="上线" inactive-text="下线" disabled @change="shopStatusChange(row.id, row.status)" />
          </template>
        </el-table-column>
        <template #empty>
          <el-empty class="vab-data-empty" description="暂无数据" />
        </template>
      </el-table>
      <el-pagination background :current-page="queryFormShop.pageNumber" :layout="layout" :page-size="queryFormShop.pageSize" :total="total" @current-change="handleCurrentChangeShop" @size-change="handleSizeChangeShop" />
      <template #footer>
        <el-button @click="showShop = false">取 消</el-button>
        <el-button type="primary" @click="saveShop">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  defineAsyncComponent,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import { deleteUser, getList, distributionShop } from '@/api/userManagement'
import { Delete, Plus, Search } from '@element-plus/icons'

import { getShopList } from '@/api/shop'
export default defineComponent({
  name: 'UserManagement',
  components: {
    Edit: defineAsyncComponent(() => import('./components/UserManagementEdit')),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      editRef: null,
      list: [],
      listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      selectRows: '',
      showShop: false,
      queryForm: {
        page: 1,
        pageSize: 10,
        usernameLike: '',
      },
      listLoading1: true,
      queryFormShop: {
        pageNo: 1,
        pageSize: 10,
        shopName: '',
      },
      totalShop: 0,
      shopList: [],
      chooseVal: {},
    })

    const setSelectRows = (val) => {
      state.selectRows = val
    }
    const handleEdit = (row) => {
      console.log(row)
      if (row.uid) {
        state['editRef'].showEdit(row)
      } else {
        state['editRef'].showEdit()
      }
    }
    const handleDelete = (row) => {
      if (row.uid) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await deleteUser(row.uid)
          proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      } 
    }
    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      fetchData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.page = val
      fetchData()
    }
    const queryData = () => {
      state.queryForm.page = 1
      fetchData()
    }
    const fetchData = async () => {
      state.listLoading = true
      const { data } = await getList(state.queryForm)
      state.list = data.list
      state.total = data.total
      state.listLoading = false
    }
    onMounted(() => {
      fetchData()
      getShopData()
    })

    // 店铺接口
    const getShopData = async () => {
      state.listLoading1 = true
      const { data } = await getShopList(state.queryFormShop)
      state.shopList = data.list
      state.totalShop = data.total
      state.listLoading1 = false
    }

    const handleCurrentChangeCheck = (val) => {
      state.chooseVal = val
    }

    const handleSizeChangeShop = (val) => {
      state.queryForm.pageSize = val
      getShopData()
    }
    const handleCurrentChangeShop = (val) => {
      state.queryForm.pageNo = val
      getShopData()
    }

    const saveShop = async () => {
      console.log(state.checkRow)
      let data = {
        accountId: state.checkRow.uid,
        shopId: state.chooseVal.id,
      }
      await distributionShop(data)
      proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      state.showShop = false
    }

    const queryShopData = () => {
      getShopData()
    }

    const handleShop = (row) => {
      state.showShop = true
      state.checkRow = row
    }

    return {
      ...toRefs(state),
      setSelectRows,
      handleEdit,
      handleDelete,
      handleSizeChange,
      handleCurrentChange,
      handleCurrentChangeCheck,
      handleSizeChangeShop,
      handleCurrentChangeShop,
      queryData,
      fetchData,
      getShopData,
      queryShopData,
      handleShop,
      saveShop,
      Delete,
      Plus,
      Search,
    }
  },
})
</script>
