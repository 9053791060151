import request from '@/utils/request'

/**
 * @description: 查询用户
 * @param {*} params
 * @return {*}
 */
export function getList(params) {
  return request({
    url: '/iam/admin/user/listPage',
    method: 'get',
    params,
  })
}

/**
 * @description: 新增用户
 * @return {*}
 */
export function doAdd(data) {
  return request({
    url: '/iam/admin/user/add',
    method: 'post',
    data,
  })
}
/**
 * @description: 更新用户
 * @param {*} data
 * @return {*}
 */
export function doEdit(data) {
  return request({
    url: '/iam/admin/user/update',
    method: 'put',
    data,
  })
}

/**
 * @description: 删除用户
 * @return {*}
 */
export function deleteUser(uid) {
  return request({
    url: `/iam/admin/user/delete/${uid}`,
    method: 'post',
  })
}

/**
 * @description: 通过uid 查询用户详情
 * @return {*}
 */
export function getUserDetailByUid(uid) {
  return request({
    url: `/iam/admin/user/getUserByUid/${uid}`,
    method: 'get',
  })
}

/**
 * @description: 重置密码
 * @return {*}
 */
export function resetUserPwd(data) {
  return request({
    url: `/iam/admin/user/resetPwd`,
    method: 'put',
    data,
  })
}

/**
 * @description: 用户分配店铺
 * @param {*} data
 * @return {*}
 */
export function distributionShop(data) {
  return request({
    url: `/cs/admin/account/distribution`,
    method: 'POST',
    data,
  })
}

/**
 * @description: 导入用户池结果
 * @param {*} data
 * @return {*}
 */
export function importErrorDataList(params) {
  return request({
    url: `/user/admin/enterprise/management/import/errorDataList`,
    method: 'GET',
    params,
  })
}

/**
 * @description: 导入用户
 * @return {*}
 */

export function enterpriseImportUser(data) {
  return request({
    url: `/user/admin/enterprise/management/import/user`,
    method: 'POST',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function exportErrorDataList(params) {
  return request({
    url: `/user/admin/enterprise/management/export/errorDataList`,
    method: 'GET',
    params,
  })
}
